import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";

import { Box, Button, Select, Container, Table, Pagination, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import { useEffect, useState } from "react";
import { GetNameChoose } from "../../funcUtils";

function HistoryBetAll() {
    const [data, setData] = useState(null);
    const [searched, setSearched] = useState("");
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [total, setTotal] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const openPopup = (item) => {
        setTotal(item);
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("data")).filter((row) => {
                const a = row?.user?.username.toString().toLowerCase().includes(searchedVal.toString());
                const b = Number(row?.user?.iduser).toString().includes(searchedVal.toString());
                return a || b;
            });
            setData(filteredRows);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
        }
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/all`, {})
            .then((res) => {
                localStorage.setItem("data", JSON.stringify(res.data.data));
                setData(res.data.data);
            })
            .catch((res) => setData(null));
    }, []);
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };
    const [table, setTable] = useState("");
    const handleChangeTable = (e) => {
        setTable(e.target.value);
    };

    const XSMN = [
        "Bạc Liêu",
        "Vũng Tàu",
        "Tiền Giang",
        "Kiên Giang",
        "Đà Lạt",
        "Bình Phước",
        "Bình Dương",
        "An Giang",
        "Bình Thuận",
        "Cà Mau",
        "Cần Thơ",
        "Hậu Giang",
        "Đồng Tháp",
        "Tây Ninh",
        "Vĩnh Long",
        "Trà Vinh",
        "Sóc Trăng",
        "Long An",
        "TP. HCM",
        "Đồng Nai"
    ];
    const XSMT = [
        "Đà Nẵng",
        "Thừa T. Huế",
        "Quảng Trị",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Ninh Thuận",
        "Kon Tum",
        "Khánh Hòa",
        "Gia Lai",
        "Bình Định",
        "Đắk Lắk",
        "Đắk Nông"
    ];

    const [st, setSt] = useState(0);
    const handleChangeStatus = (e) => {
        if (ls.status_bet === "Lose" || ls.status_bet === "Pending") {
            if (e.target.value === "Win") {
                setSt(1);
            }
        } else {
            if (e.target.value === "Lose" || e.target.value === "Pending") {
                setSt(2);
            }
        }
        setLs((prevLs) => ({
            ...prevLs,
            status_bet: e.target.value
        }));
    };

    const handleSubmitLs = (e) => {
        e.preventDefault();
        const formData = {
            id: ls._id,
            userId: ls.user._id,
            status_bet: e.target.status_bet.value,
            state: e.target.state.value,
            money: e.target.money.value,
            moneyuser: st == 1 ? e.target.moneythang.value : st == 2 ? Number(ls.moneythang) * -1 : 0,
            moneythang: e.target.moneythang.value
        };
        axios
            .patch(`${process.env.REACT_APP_API_URL}/history`, formData)
            .then((res) => {
                setShow(false);
                swal("Cập nhật thành công").then((value) => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                setShow(false);
                swal("Có lỗi vui lòng thử lại!");
            });
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            <Container maxWidth={false}>
                                <div className="container_set">Lịch sử giao dịch</div>
                                <div className="form_set">
                                    <Box>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <TextField
                                                value={searched}
                                                onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                                placeholder="Tìm kiếm"
                                                sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                            />
                                            {/*<div>
                                                <span>Chọn trò chơi </span>
                                                <select onChange={handleChangeTable} value={table} id="table">
                                                    <option value="">Tất cả</option>
                                                    <option value="1 phút">Keno 1p</option>
                                                    <option value="3 phút">Keno 3p</option>
                                                    <option value="5 phút">Keno 5p</option>
                                                    <option value="Xúc sắc 3p">Xúc sắc 3p</option>
                                                    <option value="Xúc sắc 5p">Xúc sắc 5p</option>
                                                    <option value="Xổ số 3p">Xổ số 3p</option>
                                                    <option value="Xổ số 5p">Xổ số 5p</option>
                                                    <option value="XSMB">XSMB</option>
                                                    <option value="XSMN">XSMN</option>
                                                    <option value="XSMT">XSMT</option>
                                                    <option value="Greedy">Greedy</option>
                                                </select>
                                            </div>*/}
                                        </div>
                                        <Table sx={{ width: 1200 }} className="table-admin">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Số kỳ</TableCell>
                                                    <TableCell>User</TableCell>
                                                    {/*<TableCell sx={{padding:"10px"}}>ID User</TableCell>*/}
                                                    <TableCell>Trò chơi</TableCell>
                                                    <TableCell>Người chơi đặt</TableCell>
                                                    <TableCell>Kết quả</TableCell>
                                                    <TableCell>Tài/Xỉu</TableCell>
                                                    <TableCell>Chẵn/Lẻ</TableCell>
                                                    <TableCell>Số tiền cược</TableCell>
                                                    <TableCell>Số tiền thắng</TableCell>
                                                    <TableCell>Trạng thái</TableCell>
                                                    <TableCell>Thời gian</TableCell>
                                                    <TableCell>Sửa</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {data != null ? (
                                                <TableBody>
                                                    {data?.slice((pages - 1) * 30, (pages - 1) * 30 + 30).map((item) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>{item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</TableCell>
                                                                <TableCell>{item?.user?.username}</TableCell>
                                                                {/*<TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																				{item?.user?.iduser}
																				</TableCell>*/}
                                                                <TableCell>
                                                                    {item?.sanh === "3 phút"
                                                                        ? "Keno 3p"
                                                                        : item?.sanh === "5 phút"
                                                                        ? "Keno 5p"
                                                                        : item?.sanh === "1 phút"
                                                                        ? "Keno 1p"
                                                                        : item?.sanh}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        maxWidth: "450px"
                                                                    }}>
                                                                    {item?.sanh == "Greedy" ? (
                                                                        <div>
                                                                            {Object.entries(item?.detail).map(
                                                                                ([key, value]) =>
                                                                                    value > 0 && (
                                                                                        <div key={key} style={{ width: "130px", float: "left" }}>
                                                                                            <img
                                                                                                alt=""
                                                                                                src={require(`../game/Greedy/images/food_${Number(key.slice(-1)) + 1}.png`)}
                                                                                                style={{ height: "25px", marginRight: "5px", position: "relative", top: "5px" }}
                                                                                            />
                                                                                            : {(value * 1000).toLocaleString()}
                                                                                        </div>
                                                                                    )
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            {GetNameChoose(item?.state, item?.type, item?.sanh)
                                                                                ? GetNameChoose(item?.state, item?.type, item?.sanh)
                                                                                : "Chọn " + item?.state}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {item?.sanh == "Greedy" && (
                                                                        <img
                                                                            alt=""
                                                                            src={require(`../game/Greedy/images/food_${item?.id_bet?.result}.png`)}
                                                                            style={{ height: "25px" }}
                                                                        />
                                                                    )}
                                                                    {(item?.sanh === "Xóc dĩa 3p" || item?.sanh === "Xóc dĩa 5p") && (
                                                                        <div
                                                                            className="history_xucsac"
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                transform: "scale(0.9)"
                                                                            }}>
                                                                            {item?.id_bet?.result?.split(" ").map((item) => (
                                                                                <div className={`a${item}`}></div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                    {(item?.sanh === "1 phút" || item?.sanh === "3 phút" || item?.sanh === "5 phút") && (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                transform: "scale(2)"
                                                                            }}>
                                                                            {item?.id_bet?.result?.split(" ").map((x) => (
                                                                                <div className="redball">{x}</div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                    {(item?.sanh === "Xúc sắc 3p" ||
                                                                        item?.sanh === "Xúc sắc 5p" ||
                                                                        item?.sanh === "Tài xỉu 1p" ||
                                                                        item?.sanh === "Tài xỉu 3p" ||
                                                                        item?.sanh === "Tài xỉu 5p") && (
                                                                        <div
                                                                            className="history_xucsac"
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                transform: "scale(2.5)"
                                                                            }}>
                                                                            {item?.id_bet?.result?.split(" ").map((item) => (
                                                                                <div className={`n${item}`}></div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                    {(item?.sanh === "Xổ số 3p" ||
                                                                        item?.sanh === "Xổ số 5p" ||
                                                                        item?.sanh === "XSMB" ||
                                                                        item?.sanh === "XSMT" ||
                                                                        item?.sanh === "XSMN") && (
                                                                        <>
                                                                            {item?.id_bet?.dacbiet && (
                                                                                <span className="badge" style={{ fontSize: "12px" }} onClick={() => openPopup(item?.id_bet)}>
                                                                                    Kiểm tra
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {item?.id_bet && item?.sanh != "Greedy" && (
                                                                        <>
                                                                            {item?.id_bet?.result
                                                                                ?.split(" ")
                                                                                .map(Number)
                                                                                .reduce((acc, curr) => acc + curr, 0) > 10 ? (
                                                                                <span className="t-blue">Tài</span>
                                                                            ) : (
                                                                                <span className="t-green">Xỉu</span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {item?.id_bet && item?.sanh != "Greedy" && (
                                                                        <>
                                                                            {item?.id_bet?.result
                                                                                ?.split(" ")
                                                                                .map(Number)
                                                                                .reduce((acc, curr) => acc + curr, 0) %
                                                                                2 ==
                                                                            0 ? (
                                                                                <span className="t-blue">Chẵn</span>
                                                                            ) : (
                                                                                <span className="t-green">Lẻ</span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}> {item?.money.toLocaleString()}</TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}> {item?.moneythang.toLocaleString()}</TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    <Button
                                                                        color={item?.status_bet == "Win" ? "success" : item?.status_bet == "Lose" ? "error" : "warning"}
                                                                        style={{ padding: "0" }}>
                                                                        {item?.status_bet}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>{formatDate(new Date(item?.createdAt))}</TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={{ padding: "0" }}
                                                                        onClick={() => {
                                                                            setShow(true);
                                                                            setLs(item);
                                                                        }}>
                                                                        Sửa
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            ) : (
                                                <div>Đang cập nhật</div>
                                            )}
                                        </Table>
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3
                                    }}>
                                    {data != null ? <Pagination color="primary" count={Math.floor(data.length / 30) + 1} size="small" onChange={handleChange} /> : null}
                                </Box>
                            </Container>
                        </Box>
                    }

                    {isShow === true ? (
                        <>
                            <div className="modal">
                                <div className="modaloverlay">
                                    <i className="ti-close closelogin"></i>
                                </div>
                                <div className="modalbody">
                                    <form onSubmit={handleSubmitLs}>
                                        <div className="modalinner">
                                            <div className="modalheader"> Sửa lịch sử </div>

                                            <div className="modalform">
                                                <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                    <div>Người chơi: </div>
                                                    <div>
                                                        <b>{ls?.user?.username}</b>
                                                    </div>
                                                </div>
                                                <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                    <div>Trò chơi: </div>
                                                    <div>
                                                        <b>{ls.sanh === "3 phút" ? "Keno 3p" : ls.sanh === "5 phút" ? "Keno 5p" : ls.sanh === "1 phút" ? "Keno 1p" : ls.sanh}</b>
                                                    </div>
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Lựa chọn: </div>
                                                    <TextField name="state" defaultValue={ls.state} sx={{ width: "100%" }} type="text" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Số tiền cược: </div>
                                                    <TextField name="money" defaultValue={ls.money} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Số tiền thắng: </div>
                                                    <TextField name="moneythang" defaultValue={ls.moneythang} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Trạng thái: </div>
                                                    <div>
                                                        <select name="status_bet" value={ls.status_bet} onChange={handleChangeStatus}>
                                                            <option value="Win" selected={ls.status_bet === "Win"} style={{ color: "#14B8A6" }}>
                                                                Win
                                                            </option>
                                                            <option value="Lose" selected={ls.status_bet === "Lose"} style={{ color: "#D14343" }}>
                                                                Lose
                                                            </option>
                                                            <option value="Pending" selected={ls.status_bet === "Pending"} style={{ color: "#FFB020" }}>
                                                                Pending
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="item_btn_form">
                                                <div className="modalformcontrols">
                                                    <Button type="submit">XÁC NHẬN</Button>
                                                </div>
                                                <div className="modalformcontrols">
                                                    <Button onClick={() => setShow(false)}>ĐÓNG</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    ) : null}

                    {isOpen && total ? (
                        <div className="popup-backdrop" style={{ maxWidth: "100%" }}>
                            <div className="popup-main" style={{ maxWidth: "500px", margin: "0 auto" }}>
                                <div className="popup-header" style={{ fontSize: "18px" }}>
                                    Phiên {total?.id_bet}
                                </div>
                                <div className="popup-content" style={{ padding: "0" }}>
                                    <table id="table-xsmb" className="table-result table table-bordered table-striped table-xsmb">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "10%" }}>ĐB</th>
                                                <td>
                                                    <span id="mb_prize_0" className="special-prize div-horizontal">
                                                        {total?.dacbiet}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>1</th>
                                                <td>
                                                    <span id="mb_prize_1" className="prize1 div-horizontal">
                                                        {total?.nhat}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>2</th>
                                                <td>
                                                    <span id="mb_prize_2" className="prize2 div-horizontal">
                                                        {total?.hai.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_3" className="prize2 div-horizontal">
                                                        {total?.hai.split(" ")[1]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>3</th>
                                                <td>
                                                    <span id="mb_prize_4" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_5" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_6" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_7" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[3]}
                                                    </span>
                                                    <span id="mb_prize_8" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[4]}
                                                    </span>
                                                    <span id="mb_prize_9" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[5]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>4</th>
                                                <td>
                                                    <span id="mb_prize_10" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_11" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_12" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_13" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[3]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>5</th>
                                                <td>
                                                    <span id="mb_prize_14" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_15" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_16" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_17" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[3]}
                                                    </span>
                                                    <span id="mb_prize_18" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[4]}
                                                    </span>
                                                    <span id="mb_prize_19" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[5]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>6</th>
                                                <td>
                                                    <span id="mb_prize_20" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_21" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_22" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[2]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>7</th>
                                                <td>
                                                    <span id="mb_prize_23" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_24" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_25" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_26" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[3]}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    onClick={closePopup}
                                    className="popup-close"
                                    style={{
                                        background: "#00b977",
                                        boxShadow: "none",
                                        textShadow: "none",
                                        fontSize: "18px"
                                    }}>
                                    Đóng
                                </button>
                            </div>
                        </div>
                    ) : null}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default HistoryBetAll;
